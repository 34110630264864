import React from "react"
import "../../../assets/styles/page/docproject.scss"
import { graphql, Link } from "gatsby"
import Layout from "../../../components/layout"
import ArticleCard from "../../../components/ArticleCard"
import SEO from "../../../components/seo/seo"

const project = ({ data }) => {
  const projects = data.allContentfulProjectPage.nodes[0].projectLIst
  const MetaData = data.allContentfulProjectPage.nodes[0].seo

  return (
    <>
      <SEO title={MetaData.title} description={MetaData.description} image={MetaData.image.fluid.src} />
      <Layout>
        <div className="project--page--cointainer">
          <div className="project--header">
            <h1 className="project--title">Project</h1>
            <p className="project--title--subtitles">
              Discover our latest releases and download our community resources
            </p>
          </div>
          <div className="project--list">
            {projects.map(
              (project: {
                titleOfProject: any
                projectDescription: any
                ProjectCategory: any
                bannerUrl: any
                contentful_id: any
                slug: any
              }) => {
                const { titleOfProject, projectDescription, ProjectCategory, bannerUrl, contentful_id, slug } = project
                return (
                  <Link
                    to={`/doc/project/doc-projects/${slug}`}
                    className="project--card--wrapper--container"
                    key={contentful_id}
                  >
                    <ArticleCard
                      key={contentful_id}
                      articleImage={bannerUrl.fluid.src}
                      articleHeadtext={titleOfProject}
                      articleSubtext={projectDescription}
                      meta={ProjectCategory}
                    />
                  </Link>
                )
              }
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default project

export const query = graphql`
  query projectInfo {
    allContentfulProjectPage {
      nodes {
        seo {
          title
          description
          image {
            fluid {
              src
            }
          }
        }
        projectLIst {
          projectDescription
          slug
          titleOfProject
          contentful_id
          bannerUrl {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
